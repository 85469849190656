// var posX = 0,
//   posY = 0;

// var mouseX = 0,
//   mouseY = 0;

// var cursor = $(".cursor"),
//   follower = $(".cursor-follower");

var scroll, scroll1, scroll2;

$(window).on("load", function () {
  setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 100);
  $("video").trigger("play");
});

jQuery(function () {
  setTimeout(() => {
    window.dispatchEvent(new Event("resize"));
  }, 100);

  $(".btn-next .btn-inner").mousemove(function (e) {
    $(".btn-next .cursor")
      .css("left", e.clientX - 20)
      .css("top", e.clientY + 7);
  });

  $(".btn-prev .btn-inner").mousemove(function (e) {
    $(".btn-prev .cursor")
      .css("left", e.clientX - 20)
      .css("top", e.clientY + 7);
  });

  // setTimeout(() => {
  //   $("header").removeClass("loading-first");
  // }, 500);
  $("[class*=col-lg-]").each(function (index, element) {
    // element == this
    if ($(element).children().length > 0) {
      // console.log(element);
    } else {
      $(element).addClass("empty-col");
    }
  });

  var sectionId = getUrlParameter("id");
  if (sectionId != undefined) {
    setTimeout(() => {
      scroll.scrollTo("#" + sectionId);
      window.dispatchEvent(new Event("resize"));
    }, 1000);
  }

  $(".gallery-slider").each(function (index) {
    var $this = $(this);
    $this.addClass("gallery-slider-" + index);
    $this.find(".swiper-pagination").addClass("gallery-pagination-" + index);
    $this.append($this.find(".swiper-pagination"));
    $this
      .find(".swiper-container")
      .append(
        "<div class='swiper-button-next'><div class='cursor'>Next</div></div>"
      );
    $this
      .find(".swiper-container")
      .append(
        "<div class='swiper-button-prev'><div class='cursor'>Previous</div></div>"
      );
    $this.find(".swiper-button-next").addClass("gallery-button-next-" + index);
    $this.find(".swiper-button-prev").addClass("gallery-button-prev-" + index);
    var projectDetailSlider = new Swiper(
      ".gallery-slider-" + index + " .swiper-container",
      {
        pagination: {
          el: ".gallery-pagination-" + index,
          type: "fraction",
        },
        navigation: {
          nextEl: ".gallery-button-next-" + index,
          prevEl: ".gallery-button-prev-" + index,
        },
        on: {
          init: function () {
            $(".swiper-button-next").each(function (index, element) {
              $(element).mousemove(function (e) {
                var rect = e.target.getBoundingClientRect();
                $(element)
                  .find(".cursor")
                  .css("left", e.clientX - rect.left - 20)
                  .css("top", e.clientY - rect.top + 7);
              });
            });

            $(".swiper-button-prev").each(function (index, element) {
              $(element).mousemove(function (e) {
                var rect = e.target.getBoundingClientRect();
                $(element)
                  .find(".cursor")
                  .css("left", e.clientX - rect.left - 20)
                  .css("top", e.clientY - rect.top + 7);
              });
            });
          },
        },
      }
    );
  });

  // cursorInit();
  menuHover();
  subscriptionPopup();
  menuToggle();
  locoScroll();
  itemHover();
  setColorTextContrast();
  setBackgroundColorPageWorkDetail();
  ajaxInit();
  introInit();
  searchInit();
});

function menuHover() {
  $(".menu-col").each(function () {
    var specClass = $(this).attr("class").split(" ")[1];
    if ($(window).width() > 1024) {
      $(this).hover(
        function () {
          $(this)
            .siblings()
            .addClass(specClass + "-hover");
          $(".menu-toggle img").addClass(specClass + "-hover");
        },
        function () {
          $(this)
            .siblings()
            .removeClass(specClass + "-hover");
          $(".menu-toggle img").removeClass(specClass + "-hover");
        }
      );
    }
  });
}

function introInit() {
  var length = $(".intro-images-slide img").length;
  $(".intro-images-slide img").each(function (index) {
    var i = 0;
    setInterval(() => {
      $(".intro-images-slide img").attr("style", "");
      $(".intro-images-slide img").eq(i).css({
        opacity: "1",
      });
      i = i + 1;
      if (i == length) {
        i = 0;
      }
    }, 7000);
  });
}

function setBackgroundColorPageWorkDetail() {
  try {
    var bgClass = $(".work-detail-wrap").attr("class").split(" ")[1];
    $("body").addClass(bgClass);
  } catch (error) {}
}

function menuToggle() {
  $(".menu-toggle").on("click", function () {
    $(this).toggleClass("active");
    $(".main-menu-header").toggleClass("show");
  });
}

function itemHover() {
  $(".journal-col").hover(
    function () {
      $(this).siblings().addClass("hover");
    },
    function () {
      $(this).siblings().removeClass("hover");
    }
  );
  $(".work-col").hover(
    function () {
      $(this).siblings().addClass("hover");
      $(this).addClass("hovering");
    },
    function () {
      $(this).siblings().removeClass("hover");
      $(this).removeClass("hovering");
    }
  );

  $(
    ".menu-nav ul li:first-child a, .menu-nav ul li:nth-child(2) a, .menu-bottom a "
  ).on("click", function (e) {
    $(".main-menu-header").removeClass("show");
    $(".menu-toggle").removeClass("active");
    setTimeout(() => {
      scroll.scrollTo("#" + $(this).attr("href").split("?id=")[1]);
      scroll.destroy();
      scroll.init();
      window.dispatchEvent(new Event("resize"));
    }, 300);
  });
}

// TweenMax.to({}, 0.01, {
//   repeat: -1,
//   onRepeat: function () {
//     posX += (mouseX - posX) / 9;
//     posY += (mouseY - posY) / 9;

//     TweenMax.set(follower, {
//       css: {
//         left: posX - 6,
//         top: posY - 6,
//         scale: 1,
//       },
//     });

//     TweenMax.set(cursor, {
//       css: {
//         left: mouseX,
//         top: mouseY,
//       },
//     });
//   },
// });

// $(document).on("mousemove", function (e) {
//   mouseX = e.clientX;
//   mouseY = e.clientY;
// });

// function cursorInit() {
//   $(window)
//     .mouseleave(function () {
//       follower.css({
//         opacity: "0",
//       });
//     })
//     .mouseenter(function () {
//       follower.css({
//         opacity: "1",
//       });
//     });

//   $("a")
//     .mouseenter(function () {
//       follower.css({
//         background: "#080808",
//       });
//     })
//     .mouseleave(function () {
//       follower.css({
//         background: "#bdbbbc",
//       });
//     });

//   $(".background-black a")
//     .mouseenter(function () {
//       follower.css({
//         background: "#fff",
//       });
//     })
//     .mouseleave(function () {
//       follower.css({
//         background: "#bdbbbc",
//       });
//     });
// }

(function () {
  var orig = $.fn.css;
  $.fn.css = function () {
    var result = orig.apply(this, arguments);
    $(this).trigger("stylechanged");
    return result;
  };
})();

var target = $(".about-red-digital .section-lead");
$(target).attrchange({
  trackValues: true,
  callback: function (event) {
    console.log(event.newValue);
  },
});

function subscriptionPopup() {
  $(".subscription-popup").append($('<span class="close-popup"></span>'));

  $(".close-popup").on("click", function () {
    $(".subscription-popup").remove();
  });
}

function locoScroll() {
  if ($("body").hasClass("smooth-scroll-page")) {
    try {
      scroll = new LocomotiveScroll({
        el: document.querySelector(".smooth-scroll-page .middle-fullwidth"),
        smooth: true,
        smoothMobile: false,
        offset: ["15%", 0],
        getDirection: true,
        // repeat: true,
      });
      scroll.on("scroll", function (obj) {
        // $(".menu-toggle").toggleClass("is-scrolled", obj.direction == "down");
        $(".back-link").toggleClass("is-scrolled", obj.delta.y > 50);
        // console.log(obj);
        if ($(".work-item-list").length > 0) {
          var isScrolled = false;

          var attr = $("footer").attr("data-scroll-section-inview");
          if (typeof attr !== typeof undefined && attr !== false) {
            bannerWorkSwiper.autoplay.stop();
            $(".menu-toggle img").addClass("stop-change");
          } else {
            bannerWorkSwiper.autoplay.start();
            $(".menu-toggle img").removeClass("stop-change");
          }

          if (obj.scroll.y == 0 && !isScrolled) {
            $(document).on("mousewheel DOMMouseScroll", function (event) {
              if (event.originalEvent.wheelDelta < 0) {
                if (!isScrolled)
                  scroll.scrollTo(
                    "#workSection",
                    0,
                    500,
                    [0.25, 0.0, 0.35, 1.0],
                    true
                  );
                isScrolled = true;
              }
            });
          }

          if (obj.delta.y == 0) {
            isScrolled = false;
          }
        }
      });
    } catch (error) {}
  }

  if ($("body").hasClass("news-detail-page")) {
    try {
      scroll1 = new LocomotiveScroll({
        el: document.querySelector(".news-detail-page .middle-fullwidth"),
        smooth: true,
        smoothMobile: false,
        offset: ["10%", 0],
        getDirection: true,
      });
      $("main").before($(".journal-detail-heading"));
      scroll1.destroy();
      scroll1.init();
      scroll1.on("scroll", function (obj) {
        // $(".menu-toggle").toggleClass("is-scrolled", obj.direction == "down");
        // $(".journal-detail-heading").toggleClass(
        //   "is-scrolled",
        //   obj.direction == "down"
        // );
        $(".journal-detail-heading").toggleClass(
          "is-scrolling",
          obj.delta.y > 50
        );
      });
    } catch (error) {}
  }

  if ($("body").hasClass("product-detail-page")) {
    try {
      scroll2 = new LocomotiveScroll({
        el: document.querySelector(".product-detail-page .middle-fullwidth"),
        smooth: true,
        smoothMobile: false,
        offset: ["10%", 0],
        getDirection: true,
      });

      $("main").before($(".work-detail-heading"));
      scroll2.destroy();
      scroll2.init();
      scroll2.on("scroll", function (obj) {
        // $(".menu-toggle").toggleClass("is-scrolled", obj.direction == "down");
        // $(".work-detail-heading").toggleClass(
        //   "is-scrolled",
        //   obj.direction == "down"
        // );
        $(".work-detail-heading").toggleClass("is-scrolling", obj.delta.y > 50);
      });
    } catch (error) {}
  }
}

$(".swiper-pagination-fake").text(`1 / ${$(".work-banner-item").length}`);

try {
  var bannerWorkSwiper = new Swiper(".work-banner .swiper-container", {
    speed: 1,
    effect: "fade",
    loop: true,
    autoplay: {
      delay: 5000,
    },
    fadeEffect: {
      crossFade: true,
    },
    pagination: {
      el: ".work-banner .swiper-pagination",
      type: "fraction",
    },
    navigation: {
      prevEl: ".work-banner .btn-prev",
      nextEl: ".work-banner .btn-next",
    },
    on: {
      init: function () {
        var imgActiveSrc = $(".swiper-slide-active img").attr("src");
        getImageBrightness(imgActiveSrc, function (brightness) {
          if (brightness < 127.5) {
            $(".menu-icon")
              .attr("class", "menu-icon")
              .addClass("menu-icon-light");
            $(".work-banner .swiper-btn .cursor").addClass("text-white");
          } else {
            $(".menu-icon")
              .attr("class", "menu-icon")
              .addClass("menu-icon-dark");
            $(".work-banner .swiper-btn .cursor").addClass("text-black");
          }
        });
      },
      transitionEnd: function () {
        var imgActiveSrc = $(".swiper-slide-active img").attr("src");
        getImageBrightness(imgActiveSrc, function (brightness) {
          if (brightness < 127.5) {
            $(".menu-icon")
              .attr("class", "menu-icon")
              .addClass("menu-icon-light");
            $(".work-banner .swiper-btn .cursor").attr(
              "class",
              "cursor text-white"
            );
          } else {
            $(".menu-icon")
              .attr("class", "menu-icon")
              .addClass("menu-icon-dark");
            $(".work-banner .swiper-btn .cursor").attr(
              "class",
              "cursor text-black"
            );
          }
        });
        $(".swiper-pagination-fake").text(
          $(".work-banner .swiper-pagination").text()
        );
      },
    },
  });
} catch (error) {}

function setColorTextContrast() {
  $(".work-banner-item").each(function () {
    var rgb = $(this).css("backgroundColor");
    var colors = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

    var r = colors[1];
    var g = colors[2];
    var b = colors[3];

    var o = Math.round(
      (parseInt(r) * 299 + parseInt(g) * 587 + parseInt(b) * 114) / 1000
    );

    if (o > 125) {
      $(this).css("color", "#080808");
    } else {
      $(this).css("color", "#f4f2ed");
    }
  });
}

function getImageBrightness(imageSrc, callback) {
  var img = document.createElement("img");
  img.src = imageSrc;
  img.style.display = "none";
  document.body.appendChild(img);

  var colorSum = 0;

  img.onload = function () {
    // create canvas
    var canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(this, 0, 0);

    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var data = imageData.data;
    var r, g, b, avg;

    for (var x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];

      avg = Math.floor((r + g + b) / 3);
      colorSum += avg;
    }

    var brightness = Math.floor(colorSum / (this.width * this.height));
    callback(brightness);
    $(img).remove();
  };
}

function ajaxInit() {
  $(document).on("click", ".ajaxlink", function (e) {
    e.preventDefault();
    var _this = $(this);
    var bodyCls = $(this).data("bg");
    var url = $(this).attr("href");
    var aryClasses = $("body").attr("class").split(" ");

    $.ajax({
      url: url,
      dataType: "html",
      success: function (response) {
        $(".ajaxproductlist").html($(response).find(".ajaxproductlist").html());
        for (var i = 0; i < aryClasses.length; i++) {
          if (aryClasses[i].indexOf("background") != -1) {
            $("body").removeClass(aryClasses[i]);
          }
        }
        $("body").addClass(bodyCls);
        _this.parent().addClass("active");
        _this.parent().siblings().removeClass("active");
        scroll.update();
        itemHover();
        setTimeout(() => {
          window.dispatchEvent(new Event("resize"));
        }, 200);
        if (url != window.location) {
          window.history.pushState({ path: url }, "", url);
        }
      },
    });
  });
}

function searchInit() {
  $("#workSearch").keypress(function (event) {
    var code = event.keyCode || event.which;
    if (code == 13) {
      event.preventDefault();
      let keyword = $("input#workSearch").val();
      let url = window.location.pathname + "?keyword=" + keyword;
      $.ajax({
        url: url,
        dataType: "html",
        success: function (response) {
          $(".ajaxproductlist").html(
            $(response).find(".ajaxproductlist").html()
          );
          scroll.update();
          itemHover();
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 100);
        },
      });
    }
  });

  $("#journalSearch").keypress(function (event) {
    var code = event.keyCode || event.which;
    if (code == 13) {
      event.preventDefault();
      let keyword = $("input#journalSearch").val();
      let url = window.location.pathname + "?keyword=" + keyword;
      $.ajax({
        url: url,
        dataType: "html",
        success: function (response) {
          $(".journal-list").html($(response).find(".journal-list").html());
          scroll.update();
          itemHover();
          setTimeout(() => {
            window.dispatchEvent(new Event("resize"));
          }, 100);
        },
      });
    }
  });
}

var getUrlParameter = function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;

  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");

    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined
        ? true
        : decodeURIComponent(sParameterName[1]);
    }
  }
};

/// debug code

// var imgs = document.querySelectorAll(".work-banner-item img");

// for (var x = 0; x < imgs.length; x++) {
//   imgs[x].onclick = function () {
//     getImageBrightness(this.src, function (brightness) {
//       console.log(brightness);
//     });
//   };
// }
